@import '../../style/_primitives.scss';

.assistant-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &--empty {
    height: 100%;
  }
  
  &__no-assistants {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    height: 100%;
  }

  &__no-assistants-text {
    text-align: center;
    color: $text-200;
  }
}