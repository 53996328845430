$primary-100:#FFA740;
$primary-200:#EF8100;
$primary-300:#9F5600;
$accent-100:#6CDBFF;
$accent-200:#11C5FF;
$text-100:#0D0D0D;
$text-200:#7F7F7F;
$bg-100:#FCF0E7;
$bg-200:#FFDCB3;
$bg-300:#FFCA8C;
$error-bg: #FFCCCD;
$error-text: #9F0000;
$danger-bg: #FF4D50;


// Breakpoints
$breakpoint-desktop: 992px;

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
      @content;
  }
}