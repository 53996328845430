@import '../../../style/primitives';

.chat-section-navigation {
  position: absolute;
  top: 5.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .8rem;
  padding: 0 0;
  width: 100%;
  max-width: 64rem;
  background-color: rgba($bg-100, .8);
  backdrop-filter: blur(.8rem);
  border-bottom: 1px dashed $bg-300;
  z-index: 1;

  &__back,
  &__forward {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .8rem;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__forward {
    justify-content: flex-end;
  }

  &__button-text {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    justify-content: center;
    text-align: center;

    font-family: 'Lato';
    color: $text-100;
    font-size: 1.6rem;
    font-weight: 300;
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}