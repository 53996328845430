@import '../../style/primitives';

.custom-markdown {
  p {
    margin-bottom: .8rem;
  }
  h1 {
    margin-top: 2.8rem;
    margin-bottom: .8rem;
  }
  h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: .8rem;
  }
  h3 {
    font-size: 1.6rem;
    margin-top: 1.2rem;
    margin-bottom: .8rem;
  }
  pre:has(code:empty) {
    display: none;
  }
  pre {
    margin: .8rem 0;
    font-family: monospace;
    background-color: white;
    padding: .8rem;
    white-space: pre-wrap;
    font-size: 1.2rem;
    border-radius: .8rem;
  }
  code {
    font-family: monospace;
    background-color: white;
  }
  ul, ol {
    margin-bottom: .8rem;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
    
    & > li {
      text-indent: -2.0rem;
      margin-left: 2.0rem;
    }
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    & > li {
      text-indent: -1.4rem;
      margin-left: 1.4rem;
    }
  }

  hr {
    margin-bottom: .8rem;
    border: none;
    height: 1px;
    background-color: $primary-100;
  }
}