@import '../../style/primitives';

.assistant-creation {

  main {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    overflow: hidden;
  }

  &__test-button {

    &:disabled {
      visibility: hidden;
      opacity: 0;
    }

    &--available {
      visibility: visible;
      opacity: 1;
    }
  }
}