@import '../../style/primitives';

.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .8rem;
  padding: 1.6rem 0;
  padding: 0;
  
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &--with-navigation {
    gap: 0rem;
    // padding-top: 4.3rem;
  }

  &--without-navigation {
    .chat-section {
      .chat-section__header {
        top: calc(5.1rem);
      }
      .chat-section__history {
        padding-top: calc(5.1rem + 3.6rem + 1.6rem);
      }
    }
  }
}