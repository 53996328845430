@import '../../../style/primitives';

.thinking {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .4rem;
  padding-top: .8rem;
  padding-bottom: .8rem;

  &__header {
    display: flex;
    align-items: center;
    gap: .4rem;
    color: $primary-300;

    &--clickable {
      cursor: pointer;
    }
  }

  &__content {
    width: 100%;
    background-color: $bg-100;
    padding: .4rem;
    border-radius: .8rem;
    transition: all .2s ease-in-out;
    overflow-y: scroll;

    &--visible.thinking__content--created {
      height: 12.8rem;
    }

    &--hidden {
      padding-top: 0;
      padding-bottom: 0;
      height: 0;
      overflow: hidden;
    }
  }
}