.settings-page {
  height: 100%;

  h3 {
    text-align: center;
  }

  main {
    gap: 3.2rem;
    padding-top: calc(1.6rem + 5.1rem);
    padding-bottom: 1.6rem;
  }

  // main {
  //   align-items: flex-start;

  //   div {
  //     align-items: flex-start;
  //   }
  // }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
}