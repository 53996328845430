@import '../../style/_primitives.scss';

.api-key-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  &__key-form,
  &__already-a-key {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .8rem;
  }

  input,
  button {
    text-align: center;
    width: 24rem;
  }

  &__save-button {
    margin-top: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 1;
      background-color: $bg-200;
    }
  }

  &__error {
    margin-top: 1.6rem;
  }

  &__note {
    color: $text-200;
    margin-top: 1.6rem;
    text-align: left;
  }
}