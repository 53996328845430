.image {
  display: block;
  img {
    width: 100%;
    z-index: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 512px;
    height: 100%;
    object-fit: cover;
  }
}