@import '../../style/primitives';

.chat-message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: .4rem;
  width: 88%;

  &__body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .2rem;
    border-radius: .8rem;
    background-color: $bg-200;
    padding: .8rem;
    overflow: hidden;
    //margin-right: 4rem;
  }

  &--user {
    flex-direction: row-reverse;
    align-self: flex-end;
    .chat-message__body {
      border-bottom-right-radius: 0;
      background-color: $bg-300;

      //margin-left: 4rem;
      margin-right: 0;
    }
  }
  &--bot {
    .chat-message__body {
      border-bottom-left-radius: 0; 
    }
  }

  &--bot {
    .chat-message__dragon {
      cursor: pointer;
    }
  }

  &__dragon {
    border-radius: 1.6rem;
    background-color: $bg-300;
  }

  &__dragon,
  &__dragon img {
    max-width: 2.4rem !important;
    max-height: 2.4rem !important;
  }

  &__header {
    font-size: 12px;
    margin: 0;
    display: flex;
    gap: .4rem;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__joining-agent {
    flex-shrink: 0;
    color: $accent-200;
    overflow: hidden;
  }

  &__footer {
    font-size: 12px;
    color: $text-200;

    display: flex;
    gap: .4rem;
    width: 100%;
    overflow: hidden;
    justify-content: flex-end;
  }

  &__activity {
    flex: 1 1;
    color: $text-100;
    font-style: italic;

    animation: text-gradient 2s ease-in-out infinite;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: .4rem;

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }

    li {
      margin-left: .4rem;
    }
  }

}


@keyframes text-gradient {
  0% {
    color: $text-100;
  }
  50% {
    color: $primary-200;
  }
  100% {
    color: $text-100;
  }
}