@import '../../style/_primitives.scss';

.no-key-warning {
  padding: .8rem;
  background: $error-bg;
  color: $error-text;
  border: $error-text;
  border-radius: .4rem;

  button {
    display: inline;
    margin: 0;
    padding: 0;
    color: $accent-200;
  }
}