@import '../../../style/primitives';

.chat-section {
  display: flex;
  flex-direction: column;
  gap:0;
  background: $bg-100;
  width: 100%;
  flex: 1 1;
  overflow: hidden;

  &__header {
    position: absolute;
    top: calc(5.1rem + 4.3rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.2rem;
    padding: .8rem 1.6rem;
    color: $text-200;
    width: 100%;
    max-width: 64rem;
    overflow: hidden;
    z-index: 1;
    button {
      white-space: nowrap;
      display: inline;
      padding: 0;
    }
    background-color: rgba($bg-100, .8);
    backdrop-filter: blur(.8rem);
  }

  &__main-info {
    display: flex;
    align-items: center;
    gap: .4rem;
    overflow: hidden;
    width: 100%;

    span {
      white-space: nowrap;
    }

  }

  &__no-content {
    color: $text-200;
  }

  &__title {
    font-size: 1.6rem;
    color: $text-200;
    white-space: nowrap;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__show-hide-button {
    color: $primary-200;
    
    cursor: pointer;
    &:hover {
      text-decoration: underline $primary-200;
    }
  }

  &__history {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    padding: 1.6rem;
    padding-top: calc(5.1rem + 4.3rem + 3.6rem + 1.6rem);
    padding-bottom: 0;

    &--non-active {
      padding-top: .4rem;
    }
  }

  &--collapsed .chat-section__history {
    display: none;
    visibility: hidden;
  }

  &--inactive {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  &__selected {
    opacity: .7;
  }

  &__input {
    display: flex;
    gap: .8rem;

    width: 100%;
    padding: 1.6rem;
    background: $primary-100;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);

    input {
      flex: 1 1;
      padding: 1.2rem 1.6rem;
      border-radius: .8rem;
      border: 1px solid $primary-200;
      &:focus {
        border: 1px solid $text-100;
        outline: none;
      }
    }

    button {
      padding: 1.2rem 1.6rem;
      border-radius: .8rem;
      border-style: none;
      outline: none;
      background: $accent-100;
      color: $text-100;
      font-weight: 600;

      &:hover {
        filter: brightness(1.1);
      }

      &:active {
        filter: brightness(0.9);
      }

      &:disabled {
        opacity: .3;
      }
    }
  }
}