@import '../style/_primitives.scss';

.welcome-page {
  background-color: $bg-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }

  p {
    max-width: 32rem;
    text-align: center;
  }
}