// Google Fonts Import mit Bold für Amatic SC - Added it to the index.html
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// Anwendung der Tokens und Styles für h1
h1 {
  font-family: 'Karla';
  font-weight: 200;
  color: $text-100;
  font-size: 2.4rem; // Standardmäßig für Mobile
  line-height: 1.25;
}

// Anwendung der Tokens und Styles für h2
h2 {
  font-family: 'Karla';
  font-weight: 200;
  color: $text-100;
  font-size: 2.4rem; // Standardmäßig für Mobile
  line-height: 1.25;
}

// Anwendung der Tokens und Styles für h3
h3 {
  font-family: 'Karla';
  font-weight: 600; // Bold Gewicht
  color: $text-100;
  font-size: 2.0rem; // Einheitliche Schriftgröße für Mobile und Desktop
  line-height: 1.25;
}

body,
button, input, textarea {
  font-family: 'Lato';
  font-weight: 300;
  line-height: 1.25;
  color: $text-100;
  font-size: 1.4rem; // Einheitliche Schriftgröße für Mobile und Desktop
}

.small-text {
  font-family: 'Lato';
  font-weight: 300;
  line-height: 1.25;
  font-size: 1.2rem; // Einheitliche Schriftgröße für Mobile und Desktop
}
