@import '../../../style/primitives';

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .8rem;

  &__item--selected:disabled {
    // opacity: 1;
    border: 1px solid $primary-300;
  }

  button {
    text-align: left;
  }
}