@import '../../../style/primitives';

.chat-scroll {
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: hidden;
  
  &__end {
    margin-top: 8rem;
  }
}