@import '../../style/primitives';

div.text-editor {

  main {
    padding-top: calc(1.6rem + 5.1rem);
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
    padding-bottom: 6.75rem;
  }

  footer {
    display: flex;
    gap: 1.6rem;
    box-shadow: none;

    button {
      flex: 1 1;

      &.primary {
        font-size: 1.4rem;
      }
    }
  }

    textarea {
      margin: 0;
      width: 100%;
      height: calc(100%);
      padding: .4rem;
      border-radius: .8rem;
      border: 1px solid $text-200;
    }
  }