@import '../../style/primitives';

.agent-profile {
  &.u-overlay main {
    display: flex;
  }

  div.agent-details {
    padding-top: calc(5.1rem + 1.6rem);
  }
}