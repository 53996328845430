body {
    width: 100dvw;
    height: 100dvh;
    background-color: $bg-200;
    color: $text-100;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.c-desktop-only {
    display: none !important;
    visibility: hidden !important;
}

@include desktop {
    .c-desktop-only {
        display: initial !important;
        visibility: inherit !important;
    }

    .c-mobile-only {
        display: none !important;
        visibility: hidden !important;
    }
}