button {
  padding: 1.2rem 1.6rem;
  border-radius: 2.2rem;
  border-style: none;
  outline: none;
  background: $bg-300;
  color: $text-100;
  font-weight: 300;
  cursor: pointer;
  border: 1px dashed transparent;

  &:not(:disabled):hover {
    filter: brightness(1.1);
  }

  &:not(:disabled):active {
    filter: brightness(0.9);
  }

  &:not(:disabled):focus {
    filter: brightness(1.1);
    border: 1px dashed $primary-300;
  }

  &:disabled {
    opacity: .5;
    cursor: initial;
  }

  &.primary {
    font-size: 1.4rem;
    background: $primary-100;
    font-weight: 700;
  }

  &.danger {
    background-color: $danger-bg;
    color: #FFFFFF;
  }

  &.u-link {
    padding: .8rem .8rem;
    border-radius: 0;
    background: transparent;
    color: $accent-200;
    display: flex;
    gap: .2rem;
    align-items: center;

    // for icons
    &.u-icon {
      font-size: 2.4rem;
      line-height: 1;

      & .u-button-text {
        font-size: 1.4rem;
        line-height: 1.25;
      }
    }

    &:not(:disabled):hover {
      text-decoration: underline;
    }
  
    &:not(:disabled):active {
      text-decoration: underline;
    }
  
    &:not(:disabled):focus {
      border-style: none;
      text-decoration: dashed;
    }

    &:disabled {
      opacity: .3;
    }
  }
}



input {
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  outline: none;
  background-color: $bg-200;
  border-style: none;
  border-radius: 2.2rem;
  color: $text-100;
  border: 1px solid transparent;

  &::placeholder {
    color: $primary-200;
  }

  &:focus {
    border: 1px solid $primary-300;
  }
}

strong {
  font-weight: 700;
}

.error {
  background-color: $error-bg;
  color: $error-text;
  border-radius: .8rem;
  padding: .8rem 1.6rem;
  font-weight: 300;
}

header {
  display: flex;
  align-items: center;
  gap: .8rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: .4rem 0;
  background-color: rgba($bg-300, .4);
  backdrop-filter: blur(.8rem);
  text-align: center;
  h2 {
    font-family: 'Lato';
    color: $text-100;
    font-size: 1.6rem;
    font-weight: 700;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  z-index: 2;
  // box-shadow: 0 2px 16px $bg-100;
  border-bottom: 1px solid $bg-300;
}

main {
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  padding-top: calc(5.1rem);
  padding-bottom: calc(3.2rem + 7rem);
  flex: 1 1;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 -10px 32px $bg-100;
  background-color: rgba($bg-100, .7);
  backdrop-filter: blur(.8rem);
}

.u-header-placeholder {
  width: 4.2rem;
  height: 4.2rem;
}

.u-page {
  position: relative;
  max-width: 64rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  background: $bg-100;
}

.u-overlay {
  cursor: default;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: $bg-100;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;

  overflow: hidden;
  z-index: 500;

  max-width: 64rem;
  left: auto;
  right: auto;
}