@import '../../style/primitives';

.agent-details {
  padding: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  overflow-y: scroll;
  height: auto;
  flex: 1 1;
  padding-top: calc(5.1rem + 1.6rem);
  padding-bottom: 8rem;

  &__introduction {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .8rem;
    width: 100%;
  }

  &__description {
    color: $text-200;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    width: 100%;

    h4 {
      font-size: 12px;
      font-weight: 600;
      color: $primary-300;
    }
  }
}