@import '../../../style/primitives';

.property-component {
  width: 100%;
  padding: .8rem 0;


  &__preview {
    width: 100%;
    background-color: white;
    padding: .4rem;
    border-radius: .8rem;
  }

  &__button-bar {
    display: flex;
    flex-direction: row-reverse;
    gap: .4rem;
    justify-content: space-between;
    background-color: $bg-200;
    margin-left: .8rem;
    margin-right: .8rem;
    border-bottom-left-radius: .8rem;
    border-bottom-right-radius: .8rem;
  }

  &__action-buttons {
    display: flex;
    gap: .4rem;
  }

  h4 {
    font-size: 12px;
    font-weight: 600;
    color: $primary-300;
  }

  &__version-bar {
    display: flex;
    gap: .8rem;
    align-items: center;
  }

  &__viewed-version--current {
    font-weight: 600;
  }
}

.chat-message {

  .property-component__preview {
    background-color: $bg-100;
  }
  .property-component__button-bar {
    background-color: $bg-300;
  }
}