@import '../../style/_primitives.scss';

.assistant-item {
  display: flex;
  gap: .8rem;
  background-color: $bg-200;
  align-items: center;
  padding: .8rem;
  border: 1px solid $primary-100;
  border-radius: .8rem;
  width: 100%;
  overflow: hidden;

  .c-image,
  .c-image img {
    width: 6.4rem;
    height: 6.4rem;
  }

  &__content {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    overflow: hidden;
  }

  &__purpose {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    overflow: hidden;
  }

  &__name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__role {
    color: $text-200;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    gap: 0;

  }
}
