@import '../../style/primitives';

.chat-input {
  position: relative;
  display: flex;
  gap: .8rem;

  width: 100%;

  textarea {
    min-height: 4.6rem; /* initial height */
    max-height: 10.4rem; /* maximum height */
    box-sizing: border-box;
    resize: none;
    padding: 1.2rem 1.6rem;
    font-size: 1.6rem;
    outline: none;
    background-color: $bg-200;
    border-style: none;
    border-radius: 2.2rem;
    color: $text-100;
    border: 1px solid transparent;
  
    flex: 1 1;
    padding-right: calc(.8rem + .4rem + 3.8rem);
    text-overflow: ellipsis;

    &::placeholder {
      color: $primary-200;
    }
  
    &:focus {
      border: 1px solid $primary-300;
    }
  }

  &__send.primary {
    position: absolute;
    right: .4rem;
    bottom: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 3.8rem;
    padding: 0;
    font-size: 2.4rem;
    line-height: 1;
  }
}