@import '../../style/primitives';

.agent-chat {

  main {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    display: flex;
    gap: 0;
  }

  .chat {
    display: flex;
    padding-top: 0;
    flex: 1 1;
    height: 100%;
    overflow-y: scroll;
  }
  .chat--without-navigation .chat-section {
    
    .chat-section__header {
      border-top: none;
      position: absolute;
      top: calc(5.1rem + 4.45rem)
    }
    .chat-section__history {
      padding-top: calc(5.1rem + 4.3rem + 3.6rem + 1.6rem);
    }
  }

  & .agent-details {
    padding-top: calc(5.1rem + 4.45rem + 1.6rem);
  }

  &__switch {
    position: absolute;
    top: 5.1rem;
    display: flex;
    width: 100%;
    gap: 0;
    z-index: 100;
    background-color: rgba($bg-100, .8);
    backdrop-filter: blur(.8rem);

    button.agent-chat__switch-option {
      background: transparent;
      flex: 1 1;
      border-radius: 0;
      border-bottom: 2px solid $bg-200;

      &--active,
      &.agent-chat__switch-option--active:not(:disabled):focus {
        border-bottom: 2px solid $accent-100;
        font-weight: bold;
      }

      &:not(:disabled):hover {
        filter: none;
        border-bottom: 2px solid $accent-100;
      }
    
      &:not(:disabled):active {
        filter: none;
        background: $bg-200;
      }
    
      &:not(:disabled):focus {
        filter: none;
        border-style: none;
        border-bottom: 2px solid $bg-200;
      }
    

    }
  }

  &__test-button {
    visibility: 0;
    opacity: 0;

    &--available {
      visibility: visible;
      opacity: 1;
    }
  }
}